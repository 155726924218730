const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchCustomer = async (ref) => {
    const endpoint = `${API_BASE_URL}/loans`;
    const isUUID = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(ref);
    const b = isUUID ? {uuid: ref} : {id: ref};
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(b)
    });

    if (!response.ok) {
        throw new Error('Failed to fetch loans');
    }

    return await response.json();
}

export const fetchPaymentURL = async (customerUuid, loans) => {
    const endpoint = `${API_BASE_URL}/url`;
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            uuid: customerUuid,
            loans: loans.map(loan => ({
                uuid: loan.uuid,
                payment: loan.payment
            }))
        })
    });

    if (!response.ok) {
        throw new Error('Failed to fetch payment URL');
    }

    const data = await response.json();
    return data.urlRequest;
}
