import React, {useEffect, useState} from 'react';
import {fetchCustomer} from '../../api';
import {useLocation, useNavigate} from 'react-router-dom';
import {Button} from 'primereact/button';
import {InputNumber} from "primereact/inputnumber";
import './styles.css';

function LandingPage() {
    const [customerId, setCustomerId] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const fetchAndProceed = async (ref) => {
        ref = ref + "";
        const isUUID = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(ref);
        const is15DigitNumber = /^\d{1,15}$/.test(ref);

        if (!ref || (!isUUID && !is15DigitNumber)) {
            setErrorMessage("Please enter a valid Customer ID.");
            return;
        }

        setLoading(true);
        try {
            const customer = await fetchCustomer(ref);
            //console.log('LandingPage', customer);
            navigate('/loans', {state: {customer}});
        } catch (error) {
            //console.error('Error:', error);
            setErrorMessage("There was an error fetching the loans. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            fetchAndProceed(event.currentTarget.value);
        }
    };

    useEffect(() => {
        let isMounted = true;

        const fetchWrapper = async () => {
            const params = new URLSearchParams(location.search);
            const refValue = params.get('ref');

            if (refValue && isMounted) {
                fetchAndProceed(refValue);
            }
        };

        fetchWrapper();

        return () => {
            isMounted = false;
        };
    }, [location]);

    return (
        <div className="centered-container">
            <div className="centered-panel">
                <div className="landing-input-group">
                    <InputNumber
                        id={"customerId-input"}
                        className="customerId-input"
                        placeholder="Enter your customer number to make a payment"
                        autoFocus={true}
                        required={true}
                        maxLength={25}
                        min={0}
                        onValueChange={(e) => setCustomerId(e.value)}
                        onKeyDown={handleKeyPress}
                        useGrouping={false}/>
                    <Button label={loading ? 'Loading...' : 'Continue'} onClick={() => fetchAndProceed(customerId)}
                            disabled={loading}/>
                </div>
                {errorMessage && <p className="error-msg">{errorMessage}</p>}
            </div>

        </div>
    );
}

export default LandingPage;
