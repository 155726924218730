import React from 'react';
import './styles.css';

const Header = () => {
  return (
    <header className="app-header">
      <img src="/logo.png" alt="Loan 365 Logo" className="header-logo" />
      <nav className="header-nav">
        {}
      </nav>
    </header>
  );
};

export default Header;
