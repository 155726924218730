import React from 'react';
import './styles.css';
import google from "./google_play_badge.svg";
import appstore from "./app_store_badge.svg";

const Footer = () => {
    return (
        <footer className="app-footer">
            <div className="store-container">


                <div className="store-panel store-panel-text">
                    Download our app to view your account details or apply for a new loan.
                </div>

                <div className="store-panel">
                    <a href={"https://play.google.com/store/apps/details?id=ie.loan365.customer"}
                       className="store-badge">
                        <img src={google} alt="Google Play Store Badge" className="google-store"/>
                    </a>
                    <a href="https://apps.apple.com/ie/app/loan365/id1567226859" className="store-badge">
                        <img src={appstore} alt="App Store Badge" className="apple-store"/>
                    </a>
                </div>

            </div>

            <div className="company-container">
                <p>Stalwart Investments Limited t/a Loan365, 13 West Street, Drogheda, Co. Louth A92 NCA4.</p>
            </div>
        </footer>
    );
};


export default Footer;
