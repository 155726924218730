import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {fetchPaymentURL} from "../../api";
import {Button} from 'primereact/button';
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {InputNumber} from "primereact/inputnumber";
import './styles.css';

const Loans = () => {
    // Obtain customer and loans data from the route's location state
    const location = useLocation();
    const customer = location.state?.customer;
    const loans = Array.isArray(location.state?.customer?.loans) ? location.state.customer.loans : [];

    // Define state variables
    const [loanPayments, setLoanPayments] = useState(loans.map(loan => loan.payment));
    const [totalPayment, setTotalPayment] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();

    // Recalculate the total payment whenever individual loan payment values change
    useEffect(() => {
        const total = loanPayments.reduce((sum, payment) => {
            //sum + (Number(payment) || 0)
            const p = payment + "";
            return sum + parseFloat(p.replace(/[^0-9.]/g, ''));
        }, 0);
        setTotalPayment(total);
    }, [loanPayments]);

    // Update individual loan payment value and recalculate the total
    const handlePaymentChange = (index, event) => {
        const updatedPayments = [...loanPayments];
        updatedPayments[index.rowIndex] = event.value;
        //console.log(index.rowIndex);
        setLoanPayments(updatedPayments);
    };

    // Navigate to the homepage
    const handleCancel = () => {
        navigate('/');
    };

    // Process payment with the updated loan payments
    const handlePay = async () => {
        setLoading(true);

        const requestPayments = loans.map((loan, index) => ({
            ...loan,
            payment: (loanPayments[index] + "").replace(/[^0-9.]/g, '')
        }));
        try {
            const paymentUrl = await fetchPaymentURL(customer.uuid, requestPayments);
            if (paymentUrl) {
                window.location.href = paymentUrl;
            } else {
                setErrorMessage("Failed to retrieve payment URL.");
            }
        } catch (error) {
            setErrorMessage("An error occurred. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    if (!Array.isArray(loans) || loans.length === 0) {
        if (customer?.id) {
            return (
                <div className="centered-container">
                    <div className="centered-panel centered-panel-text">
                        <p>
                            Sorry, no loans available for customer {customer.id}.
                        </p>
                        <p>Please double-check your customer number or contact us for assistance.</p>
                        <Button label="Try Again" onClick={handleCancel}/>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="centered-container">
                    <div className="centered-panel centered-panel-text">
                        <p>
                            Oops! It seems you have either navigated back or directly accessed this page without providing your customer number, such as using a bookmark.
                        </p>
                        <Button label="Click to Enter Number" onClick={handleCancel}/>
                    </div>
                </div>
            );
        }

    } else {
        return (
            <div className="centered-container">
                <div className="centered-panel">
                    <div className="loans-header">
                        <div className="customerId" style={{paddingLeft: '10px'}}>Customer {customer.id}</div>
                        <div className="loans-input-group">
                            <Button label="Cancel"
                                    className="cancel"
                                    onClick={handleCancel}
                            />
                            <Button label={loading ? 'Processing...' : `Pay €${totalPayment.toLocaleString('en-IE', {minimumFractionDigits: 2})}`}
                                    onClick={handlePay}
                                    disabled={loading || totalPayment < 1.0}
                            />
                        </div>
                    </div>
                    {errorMessage && <p className="error-msg">{errorMessage}</p>}
                    {totalPayment < 1.0 && <p className="error-msg">At least €1.00 is required to make a payment.</p>}
                    <DataTable
                     //    responsiveLayout={"stack"}
                        value={loans}
                        editMode="cell"
                        showGridlines={true}
                        size={"small"}
                        stripedRows={false}
                    >
                        <Column field="id" header="Agreement"/>
                        <Column field="outstandingBalanceDisplay" header="Balance"/>
                        <Column field="arrearsDisplay" header="Arrears"/>
                        <Column field="minimumPaymentDisplay" header="Minimum" className="minimum-payment-column" />
                        <Column header="Payment" body={(loan, index) => (
                            <InputNumber
                                className="input-payment"
                                value={loan.payment}
                                min={0}
                                max={5000}
                                required={true}
                                allowEmpty={false}
                                onChange={(e) => handlePaymentChange(index, e)}
                                mode="currency"
                                currency="EUR"
                                locale="en-IE"
                            />
                        )}/>
                    </DataTable>
                </div>
            </div>
        );
    }
};

export default Loans;
